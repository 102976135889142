import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from './pages/home/Home';
import JoinUs from './pages/joinus/JoinUs';
import About from './pages/about/About';
// import Contact from './pages/contact/Contact';
import './App.module.scss'; // Import global styles
import Curriculum from './pages/curriculum/Curriculum';
import SuccessStories from './pages/successStories/SuccessStories';
import Equipments from './pages/equipments/Equipments';
import StartYourOwn from './pages/startYourOwn/StartYourOwn';

// Create a custom Material-UI theme
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {

        }
      }
    },
  },
  typography: {
    fontFamily: [
      'Bai Jamjuree',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#ba1f2a',
    },
    secondary: {
      main: '#D4AF37',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/curriculum" element={<Curriculum />} />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/success-stories" element={<SuccessStories />} />
          <Route path="/equipment" element={<Equipments />} />
          <Route path="/start-your-pilate-studio" element={<StartYourOwn />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
