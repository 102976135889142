
import { Container, Divider, Grid } from '@mui/material';

import PageLayout from '../../components/pageLayout/PageLayout';
import headerImg from '../../assets/images/home.jpg';
import { GiMaterialsScience } from "react-icons/gi";
import { MdEngineering } from "react-icons/md";
import { IoMdStarOutline } from "react-icons/io";
import { MdOutlineDesignServices } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import { GiStarsStack } from "react-icons/gi";
import { FaUsersCog } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GiPlantRoots } from "react-icons/gi";
import reformer from '../../assets/images/reformer_solo.jpg';
import pilates_chairs from '../../assets/images/chair_solo.jpg';
import ladder_barrel from '../../assets/images/ladder_barrel.jpg';

import styles from './Equipments.module.scss';


const Equipments: React.FC = () => {

    const keyFeaturesEquipments = [
        {
            name: "High-Grade Materials",
            desc: "We use only the finest materials to construct our equipment, ensuring durability and safety.",
            icon: <GiMaterialsScience size={40} />
        },
        {
            name: "Precision Engineering",
            desc: "Each piece of equipment is designed with precision to provide smooth operation and accurate alignment.",
            icon: <MdEngineering size={40} />
        },
        {
            name: "Ergonomic Design",
            desc: "Our products are ergonomically designed to support the body’s natural movements and enhance comfort.",
            icon: <MdOutlineDesignServices size={40} />
        },
        {
            name: "Innovative Technology",
            desc: "We integrate the latest technology to improve functionality and user experience.",
            icon: <GrTechnology size={40} />
        }
    ]

    const chooseUs = [
        {
            name: "Expertise and Experience",
            desc: "With years of experience in the Pilates industry, we have a deep understanding of the needs and expectations of Pilates professionals and enthusiasts. Our expertise allows us to create equipment that enhances the effectiveness and enjoyment of Pilates practice.",
            icon: <GiStarsStack size={40} />
        },
        {
            name: "Customization Options",
            desc: "We offer customization options to meet the specific needs of our clients. Whether it’s adjusting the dimensions, selecting different materials, or personalizing the design, we can tailor our equipment to your exact requirements.",
            icon: <FaUsersCog size={40} />
        },
        {
            name: "Sustainability Practices",
            desc: "We are committed to sustainable manufacturing practices. Our processes are designed to minimize waste and reduce our environmental impact. We use eco-friendly materials and ensure that our manufacturing methods are environmentally responsible.",
            icon: <GiPlantRoots size={40} />
        },
        {
            name: "Exceptional Customer Service",
            desc: "Our team is dedicated to providing exceptional customer service. From initial inquiries to after-sales support, we are here to assist you at every step. We ensure timely delivery and offer comprehensive support to help you get the most out of our products.",
            icon: <RiCustomerService2Fill size={40} />
        }
    ]

    const products = [
        {
            name: "Reformers",
            desc: "High-performance reformers designed for smooth and silent operation. Available in various models to suit different needs.",
        },
        {
            name: "Pilates Chairs",
            desc: "Ergonomically designed chairs that provide excellent support and versatility. Ideal for a wide range of exercises.",
        },
        {
            name: "Ladder Barrels",
            desc: "Sturdy and durable ladder barrels that offer superior stability and comfort. Perfect for stretching and strengthening exercises.",
        },
        {
            name: "Small Props",
            desc: "A variety of small props, including resistance bands, foam rollers, and Pilates rings, to enhance your workouts and add variety.",
        }
    ]

    return <PageLayout>
        <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
            <Container maxWidth="lg">
                <p className='text-center text-5xl sm:text-6xl'>Pilates Equipment</p>
            </Container>
        </div>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                About Our Manufacturing Unit
            </p>
            <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
                Welcome to the Pilates Equipment Manufacturing Unit at the Indian School of Pilates. We pride ourselves on being one of the top sellers of high-quality Pilates equipment in the industry. Our state-of-the-art manufacturing unit is dedicated to producing durable, reliable, and innovative Pilates apparatus that meet the highest standards of excellence.
            </p>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                Our Commitment to Quality
            </p>
            <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
                At the Indian School of Pilates, we understand the importance of using superior equipment in Pilates practice. Our commitment to quality is evident in every piece of equipment we produce. From reformers and Pilates chairs to ladder barrels and small props, each item is meticulously crafted to ensure optimal performance and longevity.
            </p>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-center tracking-wider'>
                Key Features of Our Equipment:
            </p>
            <div className='mt-14 flex items-start justify-center flex-wrap gap-10'>
                {
                    keyFeaturesEquipments.map((item) => (
                        <div className='flex items-center justify-center flex-col w-full lg:w-5/12 gap-4'>
                            <div>{item.icon}</div>
                            <p className='text-center tracking-wider text-lg sm:text-xl'>{item.name}</p>
                            <p className='text-center text-base sm:text-lg'>{item.desc}</p>
                        </div>
                    ))
                }
            </div>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-center tracking-wider'>
                Why Choose Us?
            </p>
            <div className='mt-14 flex items-start justify-center flex-wrap gap-10'>
                {
                    chooseUs.map((item) => (
                        <div className='flex items-center justify-center flex-col w-full lg:w-5/12 gap-4'>
                            <div>{item.icon}</div>
                            <p className='text-center tracking-wider text-lg sm:text-xl'>{item.name}</p>
                            <p className='text-center text-base sm:text-lg'>{item.desc}</p>
                        </div>
                    ))
                }
            </div>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-center tracking-wider'>
                Our Products
            </p>
            <div className='flex items-center justify-center gap-10 my-10 flex-col lg:flex-row'>
                <img src={reformer} className='rounded shadow-xl h-64' />
                <img src={pilates_chairs} className='rounded shadow-xl h-64' />
            </div>
            <div className='mt-14 flex items-start justify-center flex-wrap gap-10'>
                {
                    products.map((item) => (
                        <div className='flex items-center justify-center flex-col w-full lg:w-5/12 gap-4'>
                            <p className='text-center tracking-wider text-lg sm:text-xl'>{item.name}</p>
                            <p className='text-center text-base sm:text-lg'>{item.desc}</p>
                        </div>
                    ))
                }
            </div>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="md" className={``}>
            <div className='text-2xl sm:text-3xl text-center mt-20 mb-10'>
                Contact Us
            </div>
        </Container>

        <Container maxWidth="sm" className={`mb-16`}>
            <p className='text-base lg:text-lg tracking-wider text-center'>
                Ready to upgrade your Pilates practice with top-of-the-line equipment? Contact us today to learn more about our products and customization options.
            </p>
        </Container>

    </PageLayout>
}

export default Equipments;