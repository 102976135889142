import React, { useEffect, useRef, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/png_logo.png';
import { IoHomeOutline } from "react-icons/io5";
import { TfiInfo } from "react-icons/tfi";
import { PiBookOpenTextLight } from "react-icons/pi";
import { PiHandshakeLight } from "react-icons/pi";
import { PiToolboxLight } from "react-icons/pi";
import { IoMdStarOutline } from "react-icons/io";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { ChevronDown } from "react-feather";

import styles from './Navigation.module.scss';
import { moreNavLinks } from '../../assets/constants';

const Navigation: React.FC = () => {

    const currentLocation = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [pathname, setPathname] = useState("/home");
    const dropdownRef = useRef<any>(null);
    const [showMore, setShowMore] = useState(false);

    const menuItems = [
        { text: 'Home', path: '/', icon: <IoHomeOutline /> },
        { text: 'About Me', path: '/about', icon: <TfiInfo /> },
        { text: 'Curriculum', path: '/curriculum', icon: <PiBookOpenTextLight /> },
        { text: 'Equipment', path: '/equipment', icon: <PiToolboxLight /> },
        { text: 'Success Stories', path: '/success-stories', icon: <IoMdStarOutline />, hide: true },
        { text: 'Join us', path: '/join-us', icon: <PiHandshakeLight />, hide: true },
        { text: 'Start your Pilate Studio', path: '/start-your-pilate-studio', icon: <LiaHandsHelpingSolid />, hide: true }
    ];

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Clicked outside the dropdown, close it
                setShowMore(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const navElement = (name: string, route: string, showBar: boolean) => (
        <React.Fragment key={name}>
            <div className={`w-full text-stone-800 text-left text-sm subLink`}>
                <Link
                    className={`${pathname === route ? "subLink_highlight" : ""
                        } hover:text-primary-cl block px-4 py-2 `}
                    to={route}
                >
                    {name}
                </Link>
            </div>
            {!showBar && <hr className={``} />}
        </React.Fragment>
    );

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setPathname(currentLocation.pathname);
    }, [currentLocation]);

    return (
        <div className={styles.navContainer}>
            <nav className="bg-white fixed z-10 top-0 w-screen bg-opacity-60 border-gray-200 dark:bg-gray-900 backdrop-blur-xl">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="https://flowbite.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-14" alt="logo" />
                        <span className="self-center text-base md:text-xl font-semibold whitespace-nowrap dark:text-white">INDIAN INSTITUTE OF PILATES</span>
                    </a>
                    <button onClick={() => setDrawerOpen(true)} data-collapse-toggle="navbar-default" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-800" aria-controls="navbar-default" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className='gap-8 items-center justify-center lg:flex hidden'>
                        <div className="hidden w-full lg:block md:w-auto" id="navbar-default">
                            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                {menuItems.filter((item) => !item.hide).map(item => (
                                    <Link key={item.text} to={item.path} className={`${currentLocation.pathname === item.path ? "block py-2 px-3 text-white bg-primary-cl rounded md:bg-transparent md:text-primary-cl md:p-0 dark:text-white md:dark:text-blue-500" : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-primary-cl md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"}`}>
                                        {item.text}
                                    </Link>
                                ))}
                            </ul>
                        </div>
                        <button
                            ref={dropdownRef}
                            onClick={() => setShowMore(!showMore)}
                            className={`${showMore ||
                                moreNavLinks.map((item) => item.route).includes(pathname)
                                ? "hoverDummy"
                                : "test"
                                } items-center font-medium gap-2 relative navLink hidden w-full lg:flex md:w-auto`}
                        >
                            More <ChevronDown size={20} />
                            <div
                                className={`absolute -left-32 top-10 bg-stone-100 drop-shadow-lg transition-opacity w-max ${showMore ? "opacity-100 block" : "opacity-0 hidden"
                                    }`}
                            >
                                {moreNavLinks.map((item, index) =>
                                    navElement(
                                        item.name,
                                        item.route,
                                        index === moreNavLinks.length - 1 ? true : false
                                    )
                                )}
                            </div>
                        </button>
                    </div>
                </div>
            </nav>
            <Drawer anchor="right" elevation={100} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <List className='my-10'>
                    {menuItems.map(item => (
                        <>
                            <ListItem className='flex items-center justify-center gap-4 font-semibold mr-10 ' key={item.text} component={Link} to={item.path} onClick={() => setDrawerOpen(false)}>
                                <div className='rounded-full text-white bg-primary-cl p-2'>{item.icon}</div>
                                <ListItemText className={`hover:text-primary-cl font-bold ${currentLocation.pathname === item.path && "text-primary-cl"}`} primary={item.text} />
                            </ListItem>
                            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' variant="inset" flexItem />
                        </>

                    ))}
                </List>
            </Drawer>
        </div>
    );
};

export default Navigation;
