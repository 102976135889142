
import { Container, Divider, Grid } from '@mui/material';

import PageLayout from '../../components/pageLayout/PageLayout';
import headerImg from '../../assets/images/home.jpg';
import reformer from '../../assets/images/reformer_solo.jpg';
import pilates_chairs from '../../assets/images/chair_solo.jpg';
import ladder_barrel from '../../assets/images/ladder_barrel.jpg';

import styles from './Curriculum.module.scss';


const Curriculum: React.FC = () => {

    const pilatesTraining = [{
        name: "Reformer",
        img: reformer
    }, {
        name: "Pilates Chair",
        img: pilates_chairs
    }, {
        name: "Ladder Barrel",
        img: ladder_barrel
    }
    ]

    return <PageLayout>
        <div className={`${styles.hero} flex justify-center items-center rounded bg-bottom`}>
            <Container maxWidth="lg">
                <p className='text-center text-5xl sm:text-6xl'>Curriculum</p>
            </Container>
        </div>

        <Container maxWidth="md" className={``}>
            <div className='sm:text-3xl text-2xl text-center mt-28'>
                Comprehensive Pilates Training
            </div>
        </Container>

        <div className='flex items-center justify-center lg:flex-row flex-col gap-5 mb-28'>
            {
                pilatesTraining.map((item) => (
                    <div className='w-96 flex justify-center items-center flex-col gap-10 mt-10' key={item.name}>
                        <p className='text-lg tracking-wider'>{item.name}</p>
                        <img src={item.img} className='lg:rounded-full rounded-xl lg:w-64 w-auto h-64 shadow-xl object-cover' />
                    </div>
                ))
            }
        </div>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                Our Tailored Approach
            </p>
            <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
                At the Indian Institute of Pilates, we recognize that every body is unique, with distinct needs and movement patterns. Our approach to Pilates instruction is rooted in the belief that tailored movement strategies are essential for achieving optimal health and well-being. We provide individualized training that promotes healing, builds strength, enhances endurance, and supports controlled mobility, all while fostering a deep mind-body connection. Our curriculum is designed to help you guide clients with confidence and precision, ensuring each movement is both purposeful and effective.
            </p>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                Pilates Teacher Training for a Purposeful Career
            </p>
            <p className='mt-10 text-lg sm:text-xl tracking-wider text-left'>
                Our comprehensive Pilates course is crafted for those aspiring to a rewarding career as skilled movement professionals. It also caters to licensed healthcare providers seeking to enhance their practice with advanced Pilates techniques. Through our detailed instructional methods, extensive course materials, and a supportive video training platform, we aim to create exceptional health leaders. Join our inclusive and collaborative learning environment, where we are passionate about helping you achieve your professional goals and build lasting connections within the Pilates community.
            </p>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className='my-28'>
            <p className='text-2xl sm:text-3xl text-left tracking-wider'>
                What You Will Learn
            </p>
            <p className='text-base sm:text-lg text-center lg:text-left mt-10'>
                Our Pilates teacher training program at the Indian Institute of Pilates provides you with essential knowledge and skills, including:
            </p>
            <ul className='mt-4 list-disc relative left-4 text-base sm:text-lg text-left '>
                <li>Safe and effective exercises for all body types</li>
                <li>Deep understanding of exercise purposes, primary muscles worked, and joint/muscle flexibility</li>
                <li>Techniques for progression, regression, and modification of exercises</li>
                <li>Methods for teaching posture and dynamic movement, including verbal and tactile cues</li>
                <li>Anatomic and biomechanical principles to reduce injuries and improve exercise effectiveness</li>
                <li>Studio ethics, professional behavior, and collaboration strategies with other professionals</li>
                <li>Strategies to help clients achieve their fitness goals confidently</li>
            </ul>
        </Container>

        <Container maxWidth="md" className={`my-20`}>
            <Divider orientation="horizontal" color="#ba1f2a" textAlign='left' flexItem />
        </Container>

        <Container maxWidth="lg" className={``}>
            <div className='sm:text-3xl text-2xl text-center lg:text-left mt-28'>
                Course Content
            </div>
        </Container>

        <Container maxWidth="lg" className={`my-10`}>
            <div className='flex items-center justify-center'>
                <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "row", lg: "row" }}>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Basic Anatomy
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Comprehensive study of the human skeletal and muscular systems</li>
                            <li>Identification of key muscle groups utilized in Pilates exercises</li>
                        </ul>
                    </Grid>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Basic Physiology
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Understanding the physiological responses to exercise</li>
                            <li>Impact of Pilates on cardiovascular, respiratory, and musculoskeletal systems</li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        </Container>

        <Container maxWidth="lg" className={`my-10`}>
            <div className='flex items-center justify-center'>
                <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "row", lg: "row" }}>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Basic Biomechanics
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Principles of movement and mechanics</li>
                            <li>Techniques for optimizing performance and preventing injuries</li>
                        </ul>
                    </Grid>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Posture
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Importance of correct posture in Pilates practice</li>
                            <li>Methods for improving and maintaining proper alignment</li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        </Container>

        <Container maxWidth="lg" className={`my-10`}>
            <div className='flex items-center justify-center'>
                <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "row", lg: "row" }}>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            History of Pilates
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Exploration of the origins and development of Pilates</li>
                            <li>Key contributors and historical milestones in the evolution of Pilates</li>
                        </ul>
                    </Grid>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Principles of Pilates
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Core principles: concentration, control, centering, flow, precision, and breathing</li>
                            <li>Application of these principles in Pilates practice and instruction</li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        </Container>

        <Container maxWidth="lg" className={`my-10`}>
            <div className='flex items-center justify-center'>
                <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "row", lg: "row" }}>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Equipment Introduction
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Detailed overview and hands-on training with Pilates equipment</li>
                            <li>Safe and effective use of the reformer, Pilates chair, and ladder barrel</li>
                        </ul>
                    </Grid>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Exercise Program Design
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Developing comprehensive Pilates exercise programs</li>
                            <li>Customizing workouts to meet individual client needs and goals</li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        </Container>

        <Container maxWidth="lg" className={`my-10`}>
            <div className='flex items-center justify-center'>
                <Grid container spacing={4} justifyContent={"flex-start"} alignItems={"center"} direction={{ sx: "column", sm: "column", md: "row", lg: "row" }}>
                    <Grid className='lg:mb-0 mb-20' item xs={12} lg={6} sm={12} md={6}>
                        <p className='text-xl sm:text-xl text-center lg:text-left tracking-wider'>
                            Training Positions
                        </p>
                        <ul className='mt-4 list-disc text-base sm:text-base text-left relative left-4'>
                            <li>Mastery of exercises in various positions (standing, seated, lying down)</li>
                            <li>Adaptation of training techniques to different postural requirements</li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </PageLayout>
}

export default Curriculum;